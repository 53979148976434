import { HomePage } from '@honeycomb/ui';
import {
    currentTripStateIdVar,
    GetFacadeContentQuery,
    getFacadeContentQuery,
    getPageAndGlobalProps,
} from '@honeycomb/data';
import { GetServerSidePropsContext } from 'next';

export default function Home({ getPage }: GetFacadeContentQuery) {
    return <HomePage {...{ currentTripStateIdVar }} {...{ getPage }} />;
}

export async function getServerSideProps(
    context: GetServerSidePropsContext
): Promise<{ props: GetFacadeContentQuery }> {
    context.res.setHeader('Cache-Control', 'public, max-age=600, stale-while-revalidate=3599, stale-while-error=3599');
    return getPageAndGlobalProps(context, getFacadeContentQuery('/', [], context.query?.isPreview));
}
